<template>
  <v-container>
    <v-card flat>
      <v-card-title class="pl-0">
        {{ $tc('channelProperties.heading', getChannelProperties.length, { name: getMemberChannel.ss_kurzname })
        }}
      </v-card-title>
      <v-divider class="mb-4" />
      <v-row>
        <v-col class="pl-6 pr-6">
          <search
              :value="search"
              ref="searchComponent"
              @input="search = $event"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
              multi-sort
              :headers="headers"
              :items="items"
              :search="search"
              :custom-filter="customFilter"
              :loading="$store.getters.getLoaders.channelProperties"
              :loading-text="$t('common.datatable.loading')"
              :no-data-text="$t('common.datatable.noData')"
              :no-results-text="$t('common.datatable.noResults')"
              :items-per-page="25"
              :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
              class="elevation-2 ma-3 cursor-pointer"
              @click:row="rowClick"
          >
            <template v-slot:item.obj_sortimages="{ item }">
              <v-avatar v-if="item.obj_sortimages" size="35">
                <img
                    :src="getImage(item)"
                    :lazy-src="getImage(item)"
                    :alt="'Main image of ' + item.dynamic_title"
                >
              </v-avatar>
              <v-avatar v-else size="35">
                <v-icon :color="$store.getters.getColors.secondary1">
                  mdi-image-area
                </v-icon>
              </v-avatar>
            </template>
            <template v-slot:item.obje_status="{ item }">
              <v-layout v-if="!item.obje_status" class="justify-center">
                <v-icon x-small :color="$store.getters.getColors.success">
                  mdi-checkbox-blank-circle
                </v-icon>
              </v-layout>
              <span v-else v-html="item.obje_status.replaceAll('<+++>', '<br><br>')" />
            </template>
            <template v-slot:item.obje_statusat="{ item }">
              {{ getElapsedTime(item.obje_statusat) }}
            </template>
            <template v-slot:item.specific_parameter1="{ item }">
              <v-icon
                  v-if="typeof getChannelSpecificItems(item, 1) === 'boolean'"
                  x-small
                  :color="(getChannelSpecificItems(item, 1) ? $store.getters.getColors.success : $store.getters.getColors.error)"
              >
                mdi-checkbox-blank-circle
              </v-icon>
              <span v-else>
                            {{ getChannelSpecificItems(item, 1) }}
                            </span>
            </template>
            <template v-slot:item.specific_parameter2="{ item }">
              <v-icon
                  v-if="typeof getChannelSpecificItems(item, 2) === 'boolean'"
                  x-small
                  :color="(getChannelSpecificItems(item, 2) ? $store.getters.getColors.success : $store.getters.getColors.error)"
              >
                mdi-checkbox-blank-circle
              </v-icon>
              <span v-else>
                            {{ getChannelSpecificItems(item, 2) }}
                            </span>
            </template>
            <template v-slot:item.specific_parameter3="{ item }">
              <v-tooltip v-if="getChanneldependendIcon(item, 3).condition()" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      transition="fade-transition"
                      :color="getChanneldependendIcon(item, 3).color"
                      :ref="`icon-${item.obje_objid}`"
                      size="16"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="getChanneldependendIcon(item, 3).action()"
                  >
                    {{ getChanneldependendIcon(item, 3).icon }}
                  </v-icon>
                </template>
                <span>
                                    {{ getChanneldependendIcon(item, 3).tooltip }}
                                </span>
              </v-tooltip>
              <div v-else>
                <v-icon
                    v-if="typeof getChannelSpecificItems(item, 3) === 'boolean'"
                    x-small
                    :color="(getChannelSpecificItems(item, 3) ? $store.getters.getColors.success : $store.getters.getColors.error)"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
                <span v-else>
                                    {{ getChannelSpecificItems(item, 3) }}
                                </span>
              </div>
            </template>
            <template v-slot:item.specific_parameter4="{ item }">
              <activated-deactivated-icon
                  v-if="typeof getChannelSpecificItems(item, 4) === 'boolean'"
                  :is-set="getChannelSpecificItems(item, 4)"
              />
              <span v-else>
                            {{ getChannelSpecificItems(item, 4) }}
                            </span>
            </template>
            <template v-slot:item.specific_parameter5="{ item }">
              <v-tooltip v-if="getChanneldependendIcon(item, 5).condition()" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      transition="fade-transition"
                      :color="getChanneldependendIcon(item, 5).color"
                      :ref="`icon-${item.obje_objid}`"
                      size="16"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="getChanneldependendIcon(item, 5).action()"
                  >
                    {{ getChanneldependendIcon(item, 5).icon }}
                  </v-icon>
                </template>
                <span>
                                    {{ getChanneldependendIcon(item, 5).tooltip }}
                                </span>
              </v-tooltip>
              <div v-else>
                <v-icon
                    v-if="typeof getChannelSpecificItems(item, 5) === 'boolean'"
                    x-small
                    :color="(getChannelSpecificItems(item, 5) ? $store.getters.getColors.success : $store.getters.getColors.error)"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
                <span v-else>
                                    {{ getChannelSpecificItems(item, 5) }}
                                </span>
              </div>
            </template>
            <template v-slot:item.specific_parameter6="{ item }">
              <activated-deactivated-icon
                  v-if="typeof getChannelSpecificItems(item, 6) === 'boolean'"
                  :is-set="getChannelSpecificItems(item, 6)"
              />
              <span v-else>
                            {{ getChannelSpecificItems(item, 6) }}
                            </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import ActivatedDeactivatedIcon from '@/components/Allgemein/AktivInaktivSymbol.vue'
import Search from '@/components/Allgemein/Suche.vue'
import socket from '@/mixins/socket.mixin'
import moment from 'moment'
import Vue from 'vue'
import { mapGetters } from 'vuex'

const name = 'MemberChannelProperties'

export default {
    name: name,
    components: { Search, ActivatedDeactivatedIcon },
    mixins: [socket],
    metaInfo: {
        title: name,
    },
    data() {
        return {
            name: name,
            clipboardIconColor: [],
            specificItemValue: [],
            items: [],
            ssid: 0,
            search: '',
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getMember',
            'getMemberChannel',
            'getInterfaces',
            'getChannelProperties',
            'getKey',
            'getTab',
            'getActingMemberid',
        ]),
        headers: {
            get() {
                const defaultHeaderItems = [
                    {
                        text: '',
                        value: 'obj_sortimages',
                        align: 'center',
                        divider: true,
                        width: '50px',
                    },
                    {
                        text: this.$i18n.t('common.labels.title'),
                        value: 'dynamic_title',
                        divider: true,
                        width: '200px',
                    },
                    {
                        text: this.$i18n.t('common.labels.id'),
                        value: 'obje_objid',
                        align: 'end',
                        divider: true,
                        width: '90px',
                    },
                    {
                        text: this.$i18n.t('common.labels.numberShorthand'),
                        value: 'obje_objnummer',
                        align: 'end',
                        divider: true,
                        width: '90px',
                    },
                    {
                        text: this.$i18n.t('common.labels.status'),
                        value: 'obje_status',
                        divider: true,
                        width: '350px',
                    },
                    {
                        text: this.$i18n.t('common.labels.statusFrom'),
                        value: 'obje_statusat',
                        divider: true,
                        width: '150px',
                    },
                ]

                switch (parseInt(this.$route.params.ssid)) {
                    case 2:
                        defaultHeaderItems.push(
                            {
                                text: 'Listing-ID',
                                value: 'specific_parameter1',
                                divider: true,
                                width: '120px',
                            },
                            {
                                text: 'Unit-ID',
                                value: 'specific_parameter2',
                                divider: true,
                                width: '120px',
                            },
                            {
                                text: this.$t('common.labels.ib'),
                                value: 'specific_parameter3',
                                divider: true,
                                width: '90px',
                            },
                            {
                                text: this.$t('common.labels.offset'),
                                value: 'specific_parameter4',
                                divider: true,
                                width: '150px',
                            },
                        )
                        break

                    case 10:
                        defaultHeaderItems.push(
                            {
                                text: 'Hotel-ID',
                                value: 'specific_parameter1',
                                divider: true,
                                width: '120px',
                            },
                            {
                                text: 'Room-ID',
                                value: 'specific_parameter2',
                                divider: true,
                                width: '120px',
                            },
                            {
                                text: 'Rate-ID',
                                value: 'specific_parameter3',
                                divider: true,
                                width: '120px',
                            },
                            {
                                text: this.$t('common.labels.mapped'),
                                value: 'specific_parameter4',
                                divider: true,
                                width: '110px',
                                align: 'center',
                            },
                            {
                                text: this.$t('common.labels.spatax'),
                                value: 'specific_parameter6',
                                divider: true,
                                width: '110px',
                                align: 'center',
                            },
                            {
                                text: 'Booking.com Admin',
                                value: 'specific_parameter5',
                                divider: true,
                                width: '170px',
                                align: 'center',
                            },
                        )
                        break

                    case 27:
                    case 29:
                        defaultHeaderItems.push(
                            {
                                text: 'Terminal-ID',
                                value: 'specific_parameter1',
                                divider: true,
                                width: '120px',
                            },
                            {
                                text: 'Requestor-ID',
                                value: 'specific_parameter2',
                                divider: true,
                                width: '200px',
                            },
                            {
                                text: 'PMS-Code',
                                value: 'specific_parameter3',
                                divider: true,
                                width: '200px',
                            },
                            {
                                text: 'Rate-ID',
                                value: 'specific_parameter4',
                                divider: true,
                                width: '200px',
                            },
                        )
                        break

                    case 18:
                        defaultHeaderItems.push(
                            {
                                text: 'Listing-ID',
                                value: 'specific_parameter2',
                                divider: true,
                                width: '120px',
                            },
                            {
                                text: this.$t('common.labels.syncCategory'),
                                value: 'obj2ms_categoryid',
                                divider: true,
                                width: '200px',
                            },
                            {
                                text: this.$t('common.labels.mapped'),
                                value: 'specific_parameter4',
                                divider: true,
                                width: '110px',
                                align: 'center',
                            },
                            {
                                text: 'Diagnostics',
                                value: 'specific_parameter3',
                                divider: true,
                                width: '120px',
                                align: 'center',
                            },
                        )
                        break

                    case 21:
                        defaultHeaderItems.push(
                            {
                                text: this.$t('common.labels.websiteId'),
                                value: 'specific_parameter1',
                                divider: true,
                                width: '130px',
                            },
                            {
                                text: this.$t('common.labels.primaryWebsite'),
                                value: 'specific_parameter2',
                                divider: true,
                                width: '150px',
                                align: 'center',
                            },
                        )
                        break

                    case 22:
                        defaultHeaderItems.push(
                            {
                                text: 'Hotel-Code',
                                value: 'specific_parameter1',
                                divider: true,
                                width: '200px',
                            },
                            {
                                text: 'Room-ID',
                                value: 'specific_parameter2',
                                divider: true,
                                width: '120px',
                            },
                            {
                                text: 'InvCode',
                                value: 'specific_parameter3',
                                divider: true,
                                width: '200px',
                            },
                        )
                        break

                    case 24:
                        defaultHeaderItems.push(
                            {
                                text: 'Listing-ID',
                                value: 'specific_parameter1',
                                divider: true,
                                width: '150px',
                            },
                        )
                        break

                    case 31:
                        defaultHeaderItems.push(
                            {
                                text: 'Listing-ID (FBE)',
                                value: 'specific_parameter1',
                                divider: true,
                                width: '200px',
                            },
                            {
                                text: this.$t('common.labels.offset'),
                                value: 'specific_parameter2',
                                divider: true,
                                align: 'right',
                                width: '150px',
                            },
                            {
                                text: 'Travel',
                                value: 'specific_parameter3',
                                divider: true,
                                width: '90px',
                                align: 'center',
                            },
                        )
                        break
                }

                return defaultHeaderItems
            },
        },
    },
    watch: {
        '$store.state.channelProperties': function () {
            this.$store.dispatch('setLoaders', { channelProperties: true })
            this.items = this.getChannelProperties
            this.$store.dispatch('setLoaders', { channelProperties: false })
        },
    },
    methods: {
        getExternalURL(item) {

            switch (this.ssid) {
                case 18:
                    return (item.obj2ms_listingid ? `https://www.airbnb.de/partner-portal/295/listings/${item.obj2ms_listingid}/current-snapshot` : '')

                case 10:
                    const hotelid = item.obj2ms_hotelid || item.obje_parameter3
                    return (hotelid ? `https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/home.html?lang=de&hotel_id=${hotelid}` : '')
            }
        },
        rowClick(item, _) {
            if (this.getKey.toLowerCase() === 'control') {
                const route = this.$router.resolve({
                    path: `/property/${item.obje_objid}`,
                    query: { target: 'blank' },
                })
                window.open(route.href, '_blank')
            }
        },
        getChanneldependendIcon(item, col) {
            let iconData = {}

            switch (this.ssid) {
                case 18:
                case 10:
                    iconData.icon = 'mdi-content-copy'
                    iconData.color = (this.clipboardIconColor[item.obje_objid] ? this.getColors.success : this.getColors.primary)
                    iconData.tooltip = this.getChannelSpecificItems(item, col)
                    iconData.action = () => {
                        this.copyValue(this.getChannelSpecificItems(item, col), item)
                    }
                    iconData.condition = () => {
                        if (this.ssid === 18)
                            return [3].includes(col) && item.obj2ms_listingid

                        if (this.ssid === 10)
                            return [5].includes(col) && (item.obj2ms_hotelid || item.obje_parameter3)
                    }

                    break

                case 31:
                    iconData.icon = 'mdi-open-in-new'
                    iconData.color = this.getColors.primary
                    iconData.tooltip = this.getChannelSpecificItems(item, col)
                    iconData.action = () => {
                        this.openWindow(item)
                    }
                    iconData.condition = () => {
                        return item.obj2ms_hotelid && item.obj2ms_hotelid !== ''
                    }

                    break

                default:
                    iconData.condition = () => {
                        return false
                    }
            }

            return iconData
        },
        customFilter(value, search, item) {

            const keys = [
                'obj_titel',
                'obje_objid',
                'obje_objnummer',
                'obje_externid',
                'obje_parameter1',
                'obje_parameter2',
                'obje_parameter3',
                'obje_parameter4',
                'obje_parameter5',
                'obj2ms_hotelid',
                'obj2ms_categoryid',
                'obj2ms_listingid',
                'obj2ms_importid',
                'obj2ms_rateid',
            ]

            return utils.customFilter(keys, value, search, item)
        },
        async copyValue(value, item) {

            if (this.$store.getters.getKey === 'Control')
                utils.windowOpen(this.getExternalURL(item), true)

            await utils.copyToClipboard(value)

            this.clipboardIconColor[item.obje_objid] = true

            Vue.set(this.clipboardIconColor, item.obje_objid, true)

            new Promise(resolve => {
                setTimeout(() => {
                    const audio = new Audio(require(`@/assets/sounds/pop_up_tone_short_005.mp3`))
                    audio.play()

                    resolve()

                }, 50)
            })
                .then(() => {
                    setTimeout(() => {
                        Vue.set(this.clipboardIconColor, item.obje_objid, false)
                    }, 1000)
                })
        },
        openWindow(item) {
            utils.windowOpen(`https://travel.feondi.de/de/detailseite/${item.obj2ms_hotelid}`, true)
        },
        getImage(property) {
            if (property.obj_sortimages) {
                const md5 = property.obj_sortimages.split(',')[0]
                return `https://api.feondi.de/common/image.php?u=xsigns&p=sfgashfasdfajshbfkas&art=objekt&memberid=${this.$route.params.memberid}&entityid=${property.obje_objid}&md5=${md5}`
            }

            return ''
        },
        getElapsedTime(timestamp) {
            if (timestamp)
                return moment(timestamp).fromNow()
        },
        getChannelSpecificItems(item, col) {

            if (col === 1) {
                switch (this.ssid) {
                    case 2:
                        return item.obje_externid
                    case 10:
                        return item.obje_parameter3 ? item.obje_parameter3 : item.obj2ms_hotelid
                    case 21:
                        return item.obje_externid
                    case 22:
                        return item.obje_parameter1
                    case 24:
                        return item.obj2ms_listingid
                    case 27:
                    case 29:
                        return item.ms_parameter3
                    case 31:
                        return item.obj2ms_hotelid
                    default:
                        return ''
                }

            }
            else if (col === 2) {
                switch (this.ssid) {
                    case 2:
                        return item.obje_parameter1
                    case 10:
                    case 18:
                        return item.obje_externid ? item.obje_externid : item.obj2ms_listingid
                    case 21:
                        return !!parseInt(item.obje_parameter2)
                    case 22:
                        return item.obje_externid
                    case 27:
                    case 29:
                        return item.obje_parameter3 || item.obje_parameter4
                    case 31:
                        return item.obje_parameter2
                    default:
                        return ''
                }
            }
            else if (col === 3) {
                switch (this.ssid) {
                    case 2:
                        return parseInt(item.obje_parameter2)
                    case 10:
                        return item.obje_parameter2 ? item.obje_parameter2 : item.obj2ms_rateid
                    case 18:
                        return this.getExternalURL(item)
                    case 22:
                        return item.obje_parameter2
                    case 27:
                    case 29:
                        return item.obje_externid
                    case 31:
                        return (item.obj2ms_hotelid ? `https://travel.feondi.de/de/detailseite/${item.obj2ms_hotelid}` : '')
                    default:
                        return ''
                }
            }
            else if (col === 4) {
                switch (this.ssid) {
                    case 2:
                        return item.obje_parameter3
                    case 10:
                        return item.obje_parameter3 !== ''
                    case 18:
                        return item.obje_externid !== ''
                    case 27:
                    case 29:
                        return item.obje_parameter2
                    default:
                        return ''
                }
            }
            else if (col === 6) {
                switch (this.ssid) {
                    case 10:
                        return parseFloat(item.obje_parameter6).toFixed(2).replace('.', ',')
                    default:
                        return ''
                }
            }
            else {
                switch (this.ssid) {
                    case 10:
                        return this.getExternalURL(item)
                    default:
                        return ''
                }
            }
        },
    },
    async mounted() {
        this.items = this.getChannelProperties
    },
    beforeMount() {
        this.ssid = parseInt(this.$route.params.ssid)
    },
}
</script>

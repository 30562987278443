<template>
    <v-card flat class="pr-4 pb-4 pl-4">
        <tab-title :title="$tc('interfaces.heading', getInterfaces.length)" :tooltip="true"/>
        <Dialog
            :data="{
            title: $t('common.dialogs.deleteInterface.heading', {msid: getDialog.data.ms_id}),
            text: $t('common.dialogs.deleteInterface.text', {msid: getDialog.data.ms_id, memberid: getMember.id}),
            replyAction: {
                state: true,
                btnText: $t('common.words.delete')
            }
        }"
            @replyAction="deleteMemberInterface"
        />
        <v-row>
            <v-col cols="auto">
                <v-btn
                    class="mt-3"
                    :color="getColors.secondary1"
                    tile
                    text
                    small
                    @click="$router.push(`/member/${ getMember.id }/member-channel/${ getMemberChannel.ss_id }/interface`)"
                >
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                    {{ $t('common.words.add') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pl-6 pr-6">
                <search
                    :value="search"
                    ref="searchComponent"
                    @input="search = $event"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :loading="getLoaders.interfaces"
                    :headers="headers"
                    :items="interfaces"
                    :search="search"
                    :loading-text="$t('common.datatable.loading')"
                    :no-data-text="$t('common.datatable.noData')"
                    :no-results-text="$t('common.datatable.noResults')"
                    calculate-widths
                    :items-per-page="25"
                    :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                    class="elevation-2 ma-3 cursor-pointer"
                    @contextmenu:row="showContextMenu"
                    @click:row="goToInterface"
                >
                    <template v-slot:item.ms_erhoehenumprovision="{ item }">
                            <span>
                                {{ parseFloat(item.ms_erhoehenumprovision).toFixed(2).replace('.', ',') + ' %' }}
                            </span>
                    </template>
                    <template v-slot:item.rapiif_name="{ item }">
                            <span :style="!item.rapiif_name ? 'color: ' + getColors.error : ''">
                                {{ (item.rapiif_name ? item.rapiif_name : $t('common.words.notAssigned')) }}
                            </span>
                    </template>
                    <template v-slot:item.ms_passwordisset="{ item }">
                        <span v-if="!item.ms_passwordisset" :style="'color: ' + getColors.error">
                            {{ (item.ms_passwordisset ? item.ms_passwordisset : $t('common.words.notAssigned')) }}
                        </span>
                        <v-icon v-else x-small :color="$store.getters.getColors.success">
                            mdi-check-decagram
                        </v-icon>
                    </template>
                </v-data-table>
                <v-menu
                    v-model="showMenu"
                    :position-x="x"
                    :position-y="y"
                    absolute
                    offset-y
                >
                    <v-list min-width="220px">
                        <v-layout v-for="(contextItem, contextIndex) in contextItems" :key="`contextIdex-${contextIndex}`">
                            <v-list-item
                                ripple
                                v-if="contextItem.condition()"
                                style="cursor: pointer"
                                :color="getColors.secondary1"
                                @click="contextItem.action"
                            >
                                <v-list-item-action class="mr-3">
                                    <v-icon size="24px" :color="contextItem.icon().color">
                                        {{ contextItem.icon().name }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title :style="`color: ${contextItem.title().color}`">
                                        {{ contextItem.title().text }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-layout>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import Dialog from '@/components/Allgemein/Dialog.vue'
import Search from '@/components/Allgemein/Suche.vue'
import TabTitle from '@/components/Allgemein/Tabtitel.vue'
import membersService from '@/services/members.service'
import { mapGetters } from 'vuex'

export default {
    name: 'InterfacesTab',
    components: {
        Search,
        Dialog,
        TabTitle,
    },
    metaInfo: {
        title: 'Channel',
    },
    data() {
        return {
            search: '',
            channel: {},
            dialog: true,
            clickedRow: null,
            showMenu: false,
            x: 0,
            y: 0,
        }
    },
    computed: {
        ...mapGetters([
            'getMember',
            'getColors',
            'getActingMemberid',
            'getMemberChannel',
            'getInterfaces',
            'getTab',
            'getLoaders',
            'getDialog',
        ]),
        interfaces: {
            get() {
                return this.getInterfaces
            },
            set(newVal) {
                this.$store.commit('SET_INTERFACES', newVal)
            },
        },
        headers: function () {
            return [
                {
                    text: `${ this.$i18n.t('common.labels.id') } (msid)`,
                    value: 'ms_id',
                    align: 'end',
                    divider: true,
                    width: '150px',
                },
                {
                    text: this.$i18n.t('common.labels.username'),
                    value: 'ms_username',
                    divider: true,
                    width: '180px',
                },
                {
                    text: this.$i18n.t('common.labels.subchannel'),
                    value: 'rapiif_name',
                    divider: true,
                    width: '180px',
                },
                {
                    text: this.$i18n.tc('common.labels.userCredentialsAssigned', 1),
                    value: 'ms_passwordisset',
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: '170px',
                },
                {
                    text: this.$i18n.t('common.labels.commission'),
                    value: 'ms_erhoehenumprovision',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.assignedProperties'),
                    value: 'rapiif_count',
                    align: 'end',
                    divider: true,
                    width: '170px',
                },
            ]
        },
        contextItems: function () {
            return [
                {
                    condition: () => {
                        return true
                    },
                    title: () => {
                        return {
                            text: this.$i18n.t('common.words.edit'),
                            color: this.getColors.primary,
                        }
                    },
                    icon: () => {
                        return {
                            name: 'mdi-pencil',
                            color: this.getColors.primary,
                        }
                    },
                    action: () => {
                        if (this.clickedRow)
                            this.goToInterface(this.clickedRow.item)
                    },
                },
                {
                    condition: () => {
                        return true
                    },
                    title: () => {
                        return {
                            text: this.$i18n.t('common.words.delete'),
                            color: this.getColors.error,
                        }
                    },
                    icon: () => {
                        return {
                            name: `mdi-delete`,
                            color: this.getColors.error,
                        }
                    },
                    action: () => {
                        if (this.clickedRow)
                            this.$store.commit('SET_DIALOG', { state: true, data: this.clickedRow.item })
                    },
                },
            ]
        },
    },
    methods: {
        goToInterface(item) {
            this.$router.push(`/member/${ this.getMember.id }/member-channel/${ this.getMemberChannel.ss_id }/interface/${ item.ms_id }`)
        },
        showContextMenu(e, row) {

            logger.log(`this is row in click show context menru: `, row)

            this.clickedRow = row

            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY

            this.$nextTick(() => {
                this.showMenu = true
            })
        },
        async deleteMemberInterface(memberInterface) {
            await this.$store.dispatch('setLoaders', { submit: true })
            const response = await membersService.deleteMemberInterface(memberInterface)

            if (response) {
                this.interfaces = response
                await this.$store.dispatch('resetProps', 'dialog')
                await this.$store.dispatch('setLoaders', { submit: false })

                await this.$store.dispatch('successfulBanner', `Successfully deleted interface ${ memberInterface.ms_id } for channel ${ memberInterface.ms_ssid }!`)

                this.$socket.emit('broadcastEmitter', {
                    method: 'interfaceUpdate',
                    force: true,
                    memberid: this.getActingMemberid,
                    user: this.$store.getters.getUsernameTexts,
                })
            }
        },
    },
    beforeMount() {
        if (this.channel.ss_id !== 15) {
            delete this.headers[2]
            delete this.headers[3]
            delete this.headers[5]
        }
    },
    async created() {
        this.channel = this.getMemberChannel
    },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pl-0"},[_vm._v(" "+_vm._s(_vm.$tc('channelProperties.heading', _vm.getChannelProperties.length, { name: _vm.getMemberChannel.ss_kurzname }))+" ")]),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{staticClass:"pl-6 pr-6"},[_c('search',{ref:"searchComponent",attrs:{"value":_vm.search},on:{"input":function($event){_vm.search = $event}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-2 ma-3 cursor-pointer",attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"custom-filter":_vm.customFilter,"loading":_vm.$store.getters.getLoaders.channelProperties,"loading-text":_vm.$t('common.datatable.loading'),"no-data-text":_vm.$t('common.datatable.noData'),"no-results-text":_vm.$t('common.datatable.noResults'),"items-per-page":25,"footer-props":{
                        'items-per-page-options': [25, 50, 100, -1],
                        'items-per-page-text': _vm.$t('common.datatable.rowsPerPage'),
                        'items-per-page-all-text': _vm.$t('common.words.all')
                    }},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.obj_sortimages",fn:function(ref){
                    var item = ref.item;
return [(item.obj_sortimages)?_c('v-avatar',{attrs:{"size":"35"}},[_c('img',{attrs:{"src":_vm.getImage(item),"lazy-src":_vm.getImage(item),"alt":'Main image of ' + item.dynamic_title}})]):_c('v-avatar',{attrs:{"size":"35"}},[_c('v-icon',{attrs:{"color":_vm.$store.getters.getColors.secondary1}},[_vm._v(" mdi-image-area ")])],1)]}},{key:"item.obje_status",fn:function(ref){
                    var item = ref.item;
return [(!item.obje_status)?_c('v-layout',{staticClass:"justify-center"},[_c('v-icon',{attrs:{"x-small":"","color":_vm.$store.getters.getColors.success}},[_vm._v(" mdi-checkbox-blank-circle ")])],1):_c('span',{domProps:{"innerHTML":_vm._s(item.obje_status.replaceAll('<+++>', '<br><br>'))}})]}},{key:"item.obje_statusat",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getElapsedTime(item.obje_statusat))+" ")]}},{key:"item.specific_parameter1",fn:function(ref){
                    var item = ref.item;
return [(typeof _vm.getChannelSpecificItems(item, 1) === 'boolean')?_c('v-icon',{attrs:{"x-small":"","color":(_vm.getChannelSpecificItems(item, 1) ? _vm.$store.getters.getColors.success : _vm.$store.getters.getColors.error)}},[_vm._v(" mdi-checkbox-blank-circle ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getChannelSpecificItems(item, 1))+" ")])]}},{key:"item.specific_parameter2",fn:function(ref){
                    var item = ref.item;
return [(typeof _vm.getChannelSpecificItems(item, 2) === 'boolean')?_c('v-icon',{attrs:{"x-small":"","color":(_vm.getChannelSpecificItems(item, 2) ? _vm.$store.getters.getColors.success : _vm.$store.getters.getColors.error)}},[_vm._v(" mdi-checkbox-blank-circle ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getChannelSpecificItems(item, 2))+" ")])]}},{key:"item.specific_parameter3",fn:function(ref){
                    var item = ref.item;
return [(_vm.getChanneldependendIcon(item, 3).condition())?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({ref:("icon-" + (item.obje_objid)),attrs:{"transition":"fade-transition","color":_vm.getChanneldependendIcon(item, 3).color,"size":"16"},on:{"click":function($event){$event.stopPropagation();_vm.getChanneldependendIcon(item, 3).action()}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getChanneldependendIcon(item, 3).icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getChanneldependendIcon(item, 3).tooltip)+" ")])]):_c('div',[(typeof _vm.getChannelSpecificItems(item, 3) === 'boolean')?_c('v-icon',{attrs:{"x-small":"","color":(_vm.getChannelSpecificItems(item, 3) ? _vm.$store.getters.getColors.success : _vm.$store.getters.getColors.error)}},[_vm._v(" mdi-checkbox-blank-circle ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getChannelSpecificItems(item, 3))+" ")])],1)]}},{key:"item.specific_parameter4",fn:function(ref){
                    var item = ref.item;
return [(typeof _vm.getChannelSpecificItems(item, 4) === 'boolean')?_c('activated-deactivated-icon',{attrs:{"is-set":_vm.getChannelSpecificItems(item, 4)}}):_c('span',[_vm._v(" "+_vm._s(_vm.getChannelSpecificItems(item, 4))+" ")])]}},{key:"item.specific_parameter5",fn:function(ref){
                    var item = ref.item;
return [(_vm.getChanneldependendIcon(item, 5).condition())?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({ref:("icon-" + (item.obje_objid)),attrs:{"transition":"fade-transition","color":_vm.getChanneldependendIcon(item, 5).color,"size":"16"},on:{"click":function($event){$event.stopPropagation();_vm.getChanneldependendIcon(item, 5).action()}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getChanneldependendIcon(item, 5).icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getChanneldependendIcon(item, 5).tooltip)+" ")])]):_c('div',[(typeof _vm.getChannelSpecificItems(item, 5) === 'boolean')?_c('v-icon',{attrs:{"x-small":"","color":(_vm.getChannelSpecificItems(item, 5) ? _vm.$store.getters.getColors.success : _vm.$store.getters.getColors.error)}},[_vm._v(" mdi-checkbox-blank-circle ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getChannelSpecificItems(item, 5))+" ")])],1)]}},{key:"item.specific_parameter6",fn:function(ref){
                    var item = ref.item;
return [(typeof _vm.getChannelSpecificItems(item, 6) === 'boolean')?_c('activated-deactivated-icon',{attrs:{"is-set":_vm.getChannelSpecificItems(item, 6)}}):_c('span',[_vm._v(" "+_vm._s(_vm.getChannelSpecificItems(item, 6))+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
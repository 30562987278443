<template>
    <div class="w-100 pl-4 pb-2 mb-4">
        <v-card class="pt-2" outlined>
            <member-heading-details
                channel-logo
                :title-text="`MEMBER_FIRSTNAME MEMBER_NAME's (MEMBER_ID) ${getMemberChannel.ss_kurzname} ${$tc('common.words.interface', getInterfaces.length)}`"
                :subtitle-text="$tc('common.memberHeadingDetails.channel', {name: getMemberChannel.ss_kurzname})"
            />
            <v-tabs
                v-model="tab"
                :background-color="getColors.primary"
                dark
                color="#ffffff"
                center-active
            >
                <v-tabs-slider :color="getColors.secondary1"/>
                <v-tab
                    v-for="(tabItem, index) in tabItems"
                    :key="`tabItem-${index}`"
                    :disabled="tabItem.disabled"
                    @change="tab = index"
                >
                    <v-progress-circular
                        v-if="tabItem.disabled"
                        class="mr-1"
                        indeterminate
                        size="16"
                    />
                    <v-icon v-else class="mr-1" small>
                        {{ tabItem.icon }}
                    </v-icon>
                    {{ tabItem.title }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item v-for="(tabItem, index) in tabItems" :key="`tabItem-${index}`">
                    <component v-bind:is="tabItem.component"/>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MemberHeadingDetails from '@/components/Allgemein/KundentitelMitDetails.vue'
import InterfacesTab from '@/components/Kunde/Schnittstellenverknuepfungen.vue'
import ChannelPropertiesTab from '@/components/Kunde/Schnittstellenobjekte.vue'

export default {
    name: 'Channel',
    components: {
        MemberHeadingDetails,
        InterfacesTab,
        ChannelPropertiesTab,
    },
    metaInfo: {
        title: 'Channel',
    },
    data() {
        return {
            ssid: 0,
        }
    },
    computed: {
        ...mapGetters([
            'getActingMemberid',
            'getMemberChannel',
            'getInterfaces',
            'getChannelProperties',
            'getColors',
            'getLoaders',
            'getMember',
            'getTab',
        ]),
        tab: {
            get() {
                return this.getTab
            },
            set(newValue) {
                this.$store.commit('SET_TAB', newValue)
            },
        },
        tabItems: function () {
            const tabs = [
                {
                    title: this.$i18n.t('common.tabs.interfaces'),
                    icon: 'mdi-account-details',
                    component: 'InterfacesTab',
                    disabled: (this.getLoaders.hasOwnProperty('interfaces') && this.getLoaders.interfaces),
                },
                {
                    title: this.$i18n.t('common.tabs.channelProperties'),
                    icon: 'mdi-home-switch',
                    component: 'ChannelPropertiesTab',
                    disabled: (this.getLoaders.hasOwnProperty('channelproperties') && this.getLoaders.channelproperties),
                },
            ]

            if (this.ssid === 32)
                this.$delete(tabs, 1)

            return tabs
        },
    },
    methods: {},
    async created() {

        this.ssid = parseInt(this.$route.params.ssid)

        await this.$store.dispatch('getAndSetInterfaces', { memberid: this.getActingMemberid })

        if (this.ssid !== 32)
            await this.$store.dispatch('getAndSetChannelProperties', { memberid: this.getActingMemberid })
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('resetProps', ['channelProperties', 'channelProperty', 'tab'])
        next()
    },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pr-4 pb-4 pl-4",attrs:{"flat":""}},[_c('tab-title',{attrs:{"title":_vm.$tc('interfaces.heading', _vm.getInterfaces.length),"tooltip":true}}),_c('Dialog',{attrs:{"data":{
        title: _vm.$t('common.dialogs.deleteInterface.heading', {msid: _vm.getDialog.data.ms_id}),
        text: _vm.$t('common.dialogs.deleteInterface.text', {msid: _vm.getDialog.data.ms_id, memberid: _vm.getMember.id}),
        replyAction: {
            state: true,
            btnText: _vm.$t('common.words.delete')
        }
    }},on:{"replyAction":_vm.deleteMemberInterface}}),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":_vm.getColors.secondary1,"tile":"","text":"","small":""},on:{"click":function($event){return _vm.$router.push(("/member/" + (_vm.getMember.id) + "/member-channel/" + (_vm.getMemberChannel.ss_id) + "/interface"))}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('common.words.add'))+" ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pl-6 pr-6"},[_c('search',{ref:"searchComponent",attrs:{"value":_vm.search},on:{"input":function($event){_vm.search = $event}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-2 ma-3 cursor-pointer",attrs:{"loading":_vm.getLoaders.interfaces,"headers":_vm.headers,"items":_vm.interfaces,"search":_vm.search,"loading-text":_vm.$t('common.datatable.loading'),"no-data-text":_vm.$t('common.datatable.noData'),"no-results-text":_vm.$t('common.datatable.noResults'),"calculate-widths":"","items-per-page":25,"footer-props":{
                      'items-per-page-options': [25, 50, 100, -1],
                      'items-per-page-text': _vm.$t('common.datatable.rowsPerPage'),
                      'items-per-page-all-text': _vm.$t('common.words.all')
                  }},on:{"contextmenu:row":_vm.showContextMenu,"click:row":_vm.goToInterface},scopedSlots:_vm._u([{key:"item.ms_erhoehenumprovision",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(parseFloat(item.ms_erhoehenumprovision).toFixed(2).replace('.', ',') + ' %')+" ")])]}},{key:"item.rapiif_name",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(!item.rapiif_name ? 'color: ' + _vm.getColors.error : '')},[_vm._v(" "+_vm._s((item.rapiif_name ? item.rapiif_name : _vm.$t('common.words.notAssigned')))+" ")])]}},{key:"item.ms_passwordisset",fn:function(ref){
                  var item = ref.item;
return [(!item.ms_passwordisset)?_c('span',{style:('color: ' + _vm.getColors.error)},[_vm._v(" "+_vm._s((item.ms_passwordisset ? item.ms_passwordisset : _vm.$t('common.words.notAssigned')))+" ")]):_c('v-icon',{attrs:{"x-small":"","color":_vm.$store.getters.getColors.success}},[_vm._v(" mdi-check-decagram ")])]}}])}),_c('v-menu',{attrs:{"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',{attrs:{"min-width":"220px"}},_vm._l((_vm.contextItems),function(contextItem,contextIndex){return _c('v-layout',{key:("contextIdex-" + contextIndex)},[(contextItem.condition())?_c('v-list-item',{staticStyle:{"cursor":"pointer"},attrs:{"ripple":"","color":_vm.getColors.secondary1},on:{"click":contextItem.action}},[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"size":"24px","color":contextItem.icon().color}},[_vm._v(" "+_vm._s(contextItem.icon().name)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{style:(("color: " + (contextItem.title().color)))},[_vm._v(" "+_vm._s(contextItem.title().text)+" ")])],1)],1):_vm._e()],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }